.navbar {
  min-height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-menu-icon {
  padding: 1rem;
}

.navbar-menu {
  position: fixed;
  top: 0;
  width: fit-content;
  height: 320px;
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  border-radius: 0 0 0 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.7rem;
  z-index: 100;
}

.menu-open-animation {
  animation: slideInRight;
  animation-duration: 0.3s;
}

.menu-close-animation {
  animation: slideOutRight;
  animation-duration: 0.3s;
}

.menu-pages {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 2rem;
}

.page {
  width: 100%;
  color: var(--text);
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  padding: 0.2rem 0.7rem;
}

.navbar-pages {
  flex: 1;
  display: none;
  align-items: center;
  justify-content: center;
}

.active {
  font-weight: 800;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .navbar-menu-icon {
    display: none;
  }
  .navbar-pages {
    display: flex;
  }
  .page {
    width: 180px;
    font-size: 1.2rem;
    transition: all 0.3s ease-in-out;
  }
  .page:hover {
    opacity: 0.6;
  }
}
