* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Mukta", sans-serif;
  text-decoration: none;
  list-style: none;
  user-select: none;
}

:root {
  --background: #eee;
  --text: #111;
  --text-secondary: #282828;
  --grey: #dadadb;
  --black-transparent: rgba(0, 0, 0, 0.1);
  --white-transparent: rgba(255, 255, 255, 0.05);
  --box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  --border-radius: 36px;
}

body {
  background-color: var(--background);
}

button {
  background-color: transparent;
  border: none;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.container {
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 1rem;
}

@media (min-width: 768px) {
  .main-container {
    overflow-x: hidden;
  }

  .container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
