.about-card {
  width: 100%;
  height: fit-content;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 1.5rem;
  box-shadow: var(--box-shadow);
  animation: fadeIn;
  animation-duration: 1.5s;
}

.about-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.about-item-icon {
  font-size: 1.5rem;
  color: var(--text-secondary);
  animation: bounceInLeft;
  animation-duration: 1s;
}

.about-item-text {
  font-size: 1.2rem;
  animation: bounceInRight;
  animation-duration: 1s;
}

@media (min-width: 768px) {
  .about-card {
    width: fit-content;
    height: fit-content;
    gap: 1.8rem;
    padding: 2rem 4rem;
    overflow: hidden;
  }
  .about-item {
    gap: 2rem;
  }
  .about-item-icon {
    font-size: 1.7rem;
  }
  .about-item-text {
    font-size: 1.4rem;
  }
}
