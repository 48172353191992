.footer {
  width: 100%;
  min-height: 80px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.footer-icons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1.7rem;
}

.footer-text {
  opacity: 0.7;
  font-size: 0.9rem;
  color: var(--text-secondary);
  line-height: 0.7rem;
}
