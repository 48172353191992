.subtitle {
  font-size: 2rem;
  font-weight: 200;
  color: var(--text-secondary);
  animation: bounceInUp;
  animation-duration: 1.5s;
}

@media (min-width: 768px) {
  .subtitle {
    font-size: 2.3rem;
    animation: bounceInRight;
    animation-duration: 1.5s;
  }
}
