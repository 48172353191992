.project-card {
  width: 100%;
  height: fit-content;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  outline: solid 0.5px var(--black-transparent);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.3rem;
}

.project-title {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: var(--text-secondary);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.project-tools-container {
  display: flex;
  flex-direction: column;
}

.project-tool {
  opacity: 0.6;
  line-height: 1.3rem;
}
.development {
  align-self: center;
  width: fit-content;
  font-size: 0.9rem;
  line-height: 0;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  padding: 0.1rem 1rem;
  border-radius: var(--border-radius);
  outline: solid 1px var(--black-transparent);
  box-shadow: var(--box-shadow);
}

.project-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media (min-width: 768px) {
  .project-card {
    width: 380px;
    height: 180px;
  }
}
