.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}

@media (min-width: 768px) {
  .title-container {
    width: 320px;
  }

  .hello {
    gap: 2rem;
  }
}
