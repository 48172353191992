.title {
  color: var(--text);
  font-size: 3.6rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 0.9;
  letter-spacing: 4px;
  font-weight: 800;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  animation: bounceInUp;
  animation-duration: 1s;
}

@media (min-width: 768px) {
  .title {
    font-size: 5rem;
    animation: bounceInRight;
    animation-duration: 1s;
  }
}
